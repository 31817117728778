import React from 'react';
import SectionComponent from '../components/sections/sections';

const IndexPage: React.FC = (): JSX.Element => {
  return (
    <SectionComponent filter='Start' />
  );
};

export default IndexPage;
